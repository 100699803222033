.brand-list {
  img {
    // @media(min-width: $mediaMd) {
    //   filter: grayscale(100%);
    // }

    &:hover {
      filter: none;
    }
  }
}
